'use client'
import React from 'react';
import styled from 'styled-components';
import Link from "next/link";
import {Black, hover, Transition, whites} from "@/styles/globalStyleVars";
import arrow from '@/public/images/static/arrow-right.svg'
import icon from '@/public/images/static/search.svg'
import Image from "next/image";

const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    borderRadius,
                    border,
                    width,
                    height,
                    background,
                    hoverBackground,
                    target,
                    borderColor,
                    hovercolor,
                    marginSm,
                    onClick,
                    className,
                        arrowfilter,
                    hoverborder,
                    arrowhoverfilter,
    icon
                }) => {


    return (
        <StyledBtn onClick={onClick} className={`${className ? className : null} dc-btn `}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hovercolor={hovercolor}
                   onSubmit={onSubmit}
                   marginSm={marginSm}
                   arrowfilter={arrowfilter}
                   hoverborder={hoverborder}
                   arrowhoverfilter={arrowhoverfilter}
                   background={background}
                   icon={icon}
        >
            {src && typeof src === 'string' ? (
                src?.startsWith('http') || src?.startsWith('www') ? (
                    <a href={src} target="_blank" rel="noopener noreferrer">
                        <span>{text} <Image height={11.41} width={16} src={icon ? icon : arrow} loading="eager" alt=""/></span>
                    </a>
                ) : (
                    <Link href={src || '/'}>
                        <span>{text} <Image height={11.41} width={16} loading="eager" src={icon ? icon : arrow} alt=""/></span>
                    </Link>
                )
            ) : (
                <a target={target || '_self'}>
                    <span>{text} <Image height={11.41} width={16} loading="eager" src={icon ? icon : arrow} alt=""/></span>
                </a>
            )}

        </StyledBtn>
    )
};

const StyledBtn = styled.div`
    &.dc-btn {
        margin: ${props => props.margin || '0'};
        width: ${props => props.width || 'fit-content'};
        height: ${props => props.height || '44'}px;
        cursor: pointer;
        position: relative;
        z-index: 999999;
        a {
            display: flex;
            width: max-content;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-size: ${props => props.fontSize || '16'}px;
            font-weight: ${props => props.fontWeight || 500};
            margin: 0;
            line-height: ${props => props.lineHeight || '20'}px;
            background-color: ${props => props.background || `#FFF`};
            position: relative;
            border-radius: ${props => props.borderRadius || '22'}px;
            overflow: hidden;
            z-index: 0;
            transition: all .6s ease;
            padding: 12px 36px;
            box-sizing: border-box;
            border: ${p => p.border || "1px solid #978C21"};
            color: ${props => props.color || `${whites}`};

            span {
                transition: color .3s ease;
                color: ${props => props.color || `#FFF`};
                position: relative;
                z-index: 2;

                img {
                    padding-left: 5px;
                    filter: ${props => props.arrowfilter || `none`};
                    transition: 0.6s ease;
                    transform: translateX(0);
                        // ${p => !p.icon && `display:none`}
                }
            }

            &:before {
                //bottom: 0;
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: ${p => p.hoverbackground || hover};
                height: 0;
                width: 100%;
                margin: auto;
                transition: all .5s ${Transition};
                border-radius: 22px;
            }

            &:hover {
                border: ${p => p.hoverborder || "0"};;
                span {
                    color: ${props => props.hovercolor || `#FFF`};
                }

                img {
                    filter: ${props => props.arrowhoverfilter || `none`};
                    transform: translateX(10px);
                }

                &:before {
                    height: 100%;
                }
            }

            &:focus {
                color: #222222;
            }
        }

        @media (max-width: 600px) {
            ${p => p.marginSm ? `margin:${p.marginSm}` : ''}
        }
    }




`;


export default Button;
