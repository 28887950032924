import React, {useRef} from 'react';
import { useEffect, useState } from 'react';
import {Accordion, Col, Container, Form, Modal, Row} from "react-bootstrap";
import Link from 'next/link';
import Button from "./Button";
import styled from 'styled-components';
import {hover, primary, text, title} from "@/styles/globalStyleVars";
import {gsap, TimelineLite} from 'gsap';
import {usePathname, useRouter} from "next/navigation";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const MyComponent = () => {
    // const store = useSelector(store => store?.home)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const  pathname  = usePathname();

    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll ) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll ) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);




    // on click hide menu
    useEffect(() => {
        const getAList = document.querySelectorAll('.main-menu__right li');
        getAList.forEach(e => {
            e.addEventListener('click', function (i) {

                if (e.querySelector('ul')) {
                    e.querySelector('ul').style.display = 'none'
                }
                setTimeout(() => {
                    const getUl = document.querySelectorAll('.main-menu__right li ul')
                    getUl.forEach(e => {
                        e.removeAttribute('style')
                    })
                }, 300)

            });
        })

        const getA = document.querySelectorAll('.main-menu__left li ul li a');
        getA.forEach(e => {
            e.addEventListener('click', function (i) {
                document.querySelector('.main-menu').style.display = 'none';
                setTimeout(() => {
                    document.querySelector('.main-menu').removeAttribute('style')
                }, 500)
            });
        })

    }, [])



    //Mobile Menu

    const mobileMenuRaf = useRef();
    const menuRef = useRef();
    const hamburgerRef = useRef();
    const hamburgerCloseRef = useRef();

    useEffect(() => {
        // menu click on mobile

        function calculateHeight() {
            let vh = window.innerHeight * 0.01;
            document?.documentElement?.style.setProperty('--vh', `${vh}px`);
            let menuCloseAction = document?.querySelector('.mobile-menu__items');
            if (menuCloseAction) {
                menuCloseAction.style.height = `${vh * 100}px`;
            }
        }

        window.addEventListener('resize', calculateHeight);            //to stop scrolling after clicking the menu button

        hamburgerRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.add("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.add("menu-visible");
            document.body.classList.add("menu-showed");
            document.body.classList.add("no-scroll");
            calculateHeight()
            gsap.to(menuRef.current, {
                display: "block",
            });
            gsap.to(menuRef.current, {
                opacity: 1,
                duration: ".3",
            });
        });

        hamburgerCloseRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-visible");
            // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
            document.body.classList.remove('contact-slided')
            document.body.classList.remove("menu-showed");
            document.body.classList.remove("no-scroll");
            gsap.to(menuRef.current, {
                opacity: 0,
                duration: ".2",
            });
            gsap.to(menuRef.current, {
                display: "none",
            });
        });

        let getLinkTag = document.querySelectorAll("a");

        for (let i of getLinkTag) {
            i.addEventListener("click", () => {
                document.querySelector(".hamburger").classList.remove("menu-open");
                document.querySelector(".Mobile-menu-wrap").classList.remove("menu-visible");
                // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
                document.body.classList.remove('contact-slided')
                document.body.classList.remove("menu-showed");
                document.body.classList.remove("no-scroll");
                gsap.to(menuRef.current, {
                    opacity: 0,
                    duration: ".2",
                });
                gsap.to(
                    menuRef.current,
                    {
                        display: "none",
                    },
                    "-=.2"
                );

                // gsap.to('.desktop-menu__bottom ul li ul', {
                //     opacity: 0,
                //     visibility: 'hidden',
                //     top: '70px',
                //     duration: .2,
                //     zIndex: -2
                // })
                // setTimeout(() => {
                //     let getAllSub = document.querySelectorAll('.desktop-menu__bottom ul li ul');
                //     getAllSub.forEach(function (e) {
                //         e.removeAttribute('style')
                //     })
                //
                // }, 1000)

            });
        }

    }, []);

    const router = useRouter();

    const handleMenuClick = (path) => {
        // Trigger route change immediately
        router.push(path);
    };



    return (

        <>
            {/*desktop menu */}
            <StyledComponent className={'main-menu'}>
                <Container>
                    <Row>
                        <Col>
                            <div className="upper-menu">
                                <ul>
                                    {/*<li><Link  href={'/privacy-policy'}><p>Privacy Policy</p></Link></li>*/}
                                    <li><Link href={'#'}>User Login</Link></li>
                                    <li><Link prefetch={true}  href={'/contact'}>Contact Us</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className={'d-flex justify-content-between '}>
                    <div className="menu-container">
                        <div className="main-menu__left">
                            <div className="main-menu__left__logo">
                                <Link prefetch={true} href={'/'}><p><img height={40} width={168} src="/images/static/logo.svg" alt=""/>
                                </p></Link>
                            </div>
                        </div>
                        <div className="main-menu__right">
                            <ul>
                                <li>
                                    <Link prefetch={true} href="/about-us" className={pathname === '/about-us' ? 'active' : ''}>
                                        About Us
                                    </Link>
                                    <ul className={'sub-menu'}>
                                        <li>
                                            <Link prefetch={true}
                                                href={'/about-us#story'}
                                                className={`${pathname === '/about-us#story' ? 'active' : ''}`}
                                            >
                                                Our Story
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={true}
                                                href={'/about-us#mission'}
                                                className={`${pathname === '/about-us#mission' ? 'active' : ''}`}
                                            >
                                                Vision & Mission
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={true}
                                                href={'/about-us#value'}
                                                className={`${pathname === '/about-us#value' ? 'active' : ''}`}
                                            >
                                                Core Values
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={true}
                                                href={'/about-us#directors'}
                                                className={`${pathname === '/about-us#directors' ? 'active' : ''}`}
                                            >
                                                Board of Directors
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                href={'/about-us#leaders'}
                                                className={`${pathname === '/about-us#leaders' ? 'active' : ''}`}
                                            >
                                                Our Leadership
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={true}
                                                href={'/about-us#award'}
                                                className={`${pathname === '/about-us#award' ? 'active' : ''}`}
                                            >
                                                Awards & Recognitions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link prefetch={true}
                                                href={'/about-us#footprint'}
                                                className={`${pathname === '/about-us#footprint' ? 'active' : ''}`}
                                            >
                                                Shanta's Footprint
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link  href="#" className={pathname === '/service' ? 'active' : ''}>
                                        Services
                                    </Link>
                                    <ul className={'sub-menu'}>
                                        <li>
                                            <Link  prefetch={true}
                                                href={'/services/investment-banking'}
                                                className={`${pathname === '/services/investment-banking' ? 'active' : ''}`}
                                            >
                                                Investment Banking
                                            </Link>
                                        </li>
                                        <li>
                                            <Link  prefetch={true}
                                                href={'/services/portfolio-management'}
                                                className={`${pathname === '/services/portfolio-management' ? 'active' : ''}`}
                                            >
                                                Portfolio Management
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link prefetch={true}  href="/transactions" className={pathname === '/transactions' ? 'active' : ''}>
                                        Transactions
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={true} href="/form-download"
                                          className={pathname === '/form-download' ? 'active' : ''}>
                                        Forms & Downloads
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={true}  href="/newsroom" className={pathname === '/newsroom' ? 'active' : ''}>
                                        Newsroom
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={true}  href="/career" className={pathname === '/career' ? 'active' : ''}>
                                        Career
                                    </Link>
                                </li>

                            </ul>
                            <Link prefetch={true} href="/search">
                                <p className="search">
                                    <img height={24} width={24} src="/images/static/search.svg" alt=""/>
                                </p>
                            </Link>
                        </div>
                    </div>

                </Container>
            </StyledComponent>

            {/*mobile menu*/}
            <StyledMobileMenu className="Mobile-menu-wrap menu-bar">
                <Container>
                    <Row className="mobile-menu" ref={mobileMenuRaf}>
                        <Col className="logo">
                            <Link prefetch={true} href={"/"}>
                                <img alt={'logo'} src={'/images/static/logo.svg'}/>
                            </Link>
                        </Col>

                        <Col className="hamburger">
                          <span ref={hamburgerRef}>
                            <img alt={'hamburger'} src={'/images/static/hamburger.svg'}/>
                          </span>

                            <span ref={hamburgerCloseRef}>
                            <img alt={'close'} src={'/images/static/close-btn.svg'}/>
                          </span>
                        </Col>

                        <div className="mobile-menu__items" ref={menuRef}>




                            <div className="mobile-search">
                                <Button text={'Search'} src={'/search'}  background={primary} icon={'/images/static/search.svg'} />
                                {/*<form>*/}
                                {/*    <div className="search-input">*/}
                                {/*        <img src="/images/static/search-icon.svg" alt="seach-icon"/>*/}

                                {/*        <input type="text" placeholder="Search what you want" className="form-control"/>*/}
                                {/*    </div>*/}
                                {/*</form>*/}
                            </div>
                            <ul className="mobile-menu__items__ul">
                                <li className={pathname === '/' ? 'active' : ''}>
                                    <Link prefetch={true} href={"/"}>
                                        Home
                                    </Link>

                                </li>
                                {/*<li className={pathname === '/about-us' ? 'active' : ''}>*/}
                                {/*    <Link href={"/about-us"}>*/}
                                {/*        About Us*/}
                                {/*    </Link>*/}

                                {/*</li>*/}
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header
                                            className={`has-child ${pathname === '/about-us' ? 'active' : ''}`}>

                                            About Us
                                            <img src={'/images/static/plus.svg'}/>{" "}
                                            <img src={'/images/static/minus.svg'}/>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#story"}> Our Story
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#mission"}> Vision & Mission
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#value"}> Core Values
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#directors"}> Board of Directors
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#leaders"}> Our Leadership
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#award"}> Awards and Recognition
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/about-us#footprint"}> Shanta’s Footprint
                                                    </Link>
                                                </li>

                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion defaultActiveKey="">
                                <Accordion.Item eventKey="0">
                                        <Accordion.Header
                                            className={`has-child ${pathname === '/service' ? 'active' : ''}`}>

                                            Service
                                            <img src={'/images/static/plus.svg'}/>{" "}
                                            <img src={'/images/static/minus.svg'}/>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    <Link prefetch={true} href={"/services/investment-banking"}> Investment Banking
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link prefetch={true} href={"/services/portfolio-management"}> Portfolio Management
                                                    </Link>
                                                </li>

                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <li className={pathname === '/transactions' ? 'active' : ''}>
                                    <Link prefetch={true} href={"/transactions"}>
                                        Transactions
                                    </Link>

                                </li>
                                <li className={pathname === '/form-download' ? 'active' : ''}>
                                    <Link prefetch={true} href={"/form-download"}>
                                        Forms & Downloads
                                    </Link>

                                </li>
                                <li className={pathname === '/newsroom' ? 'active' : ''}>
                                    <Link prefetch={true} href={"/newsroom"}>
                                        Newsroom
                                    </Link>

                                </li>
                                <li className={pathname === '/career' ? 'active' : ''}>
                                    <Link prefetch={true} href={"/career"}>
                                        Career
                                    </Link>

                                </li>
                                <div className="mobile-menu__items__bottom">
                                    <ul>
                                        {/*<li className={pathname === '/privacy-policy' ? 'active' : ''}>
                                            <Link href={"/privacy-policy"}>
                                                Privacy Policy
                                            </Link>

                                        </li>*/}
                                        <li className={pathname === '/user-login' ? 'active' : ''}>
                                            <Link prefetch={true} href={"#"}>
                                                User Login
                                            </Link>

                                        </li>
                                        <li className={pathname === '/contact' ? 'active' : ''}>
                                            <Link prefetch={true} href={"/contact"}>
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </ul>

                        </div>

                    </Row>
                </Container>
            </StyledMobileMenu>


        </>

    );
};

// desktop menu
const StyledComponent = styled.section`
    //height: 80px;
    //background-color: #fff;
    //backdrop-filter: blur(5px);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: .5s ease;
    //opacity: 0;
    @media (max-width: 767px) {
        display: none !important;
    }
    
    .sub-menu{
        li{
            a{
                font-weight: 500 !important;
                p{
                    font-weight: 500 !important;
                }
            }
        }
    }

    .upper-menu {
        display: flex;
        justify-content: flex-end;

        ul {
            display: flex;

            li {
                padding-top: 30px;
                padding-bottom: 20px;

                a {
                    color: #FFF;
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    &.active {
                        color: ${hover};
                    }
                    &:hover{
                        color: #FFF !important;
                    }
                    @media(max-width: 1366px) and (min-width: 1025px){
                        font-size: 15px;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #FFF;
                    @media(max-width: 1366px) and (min-width: 1025px){
                        font-size: 15px;
                    }
                }

                &:not(:nth-last-child(1)) a {
                    margin-right: 20px;
                }


                ul {
                    position: absolute;
                    background-color: #F2F2F2;
                    padding: 30px 20px;
                    min-width: 250px;
                    visibility: hidden;
                    opacity: 0;
                    transition: .4s ease;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
                    border-radius: 10px;
                    li {
                        a {
                            font-weight: 500;
                            color: ${text};
                            height: fit-content;
                            margin-right: 0 !important;
                        }

                        &:not(:nth-last-child(1)) a {
                            border-bottom: 1px solid rgba(60, 60, 59, 0.30);
                            padding-bottom: 12px;
                            margin-bottom: 17px;
                        }
                    }
                }

                &:hover {
                    ul {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    .menu-container{
        background: #FFF;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        border-radius: 5px;
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);
        @media(min-width: 1920px){
            height: 80px;
        }
    }


  .main-menu__left {
    display: flex;
    align-items: center;

    &__logo {
      margin-right: 50px;
    }

    
  }

  .main-menu__right {
    display: flex;
    align-items: center;
    gap: 30px;
      
      .sub-menu{
          li{
              a{
                  &:before{
                      display: none;
                  }
              }
          }
      }
    .search {
      min-height: 60px;
      max-height: 60px;
      min-width: 60px;
      max-width: 60px;
      background: ${primary};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      transition: .5s ease;
        border-radius: 0 5px 5px 0;

      img {
        z-index: 2;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0 !important;
        background-color: ${hover};
        margin: auto;
        transition: all .5s ease;
        height: 0;
        width: 100%;
          border-radius: 0 5px 5px 0;
      }

      &:hover {
        border: 1px solid ${hover};

        &:before {
          height: 100%;
        }
      }
        
        @media(min-width: 1920px){
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
        }
    }

      ul {
          &:not(li ul) {
              display: flex;
          }

          li {
              a {
                  color: #3C3C3B;
                  height: 62px;
                  display: flex;
                  align-items: center;
                  text-transform: capitalize;
                  position: relative;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 500;
                  @media(max-width: 1367px) and (min-width: 1025px){
                      font-size: 15px;
                  }
                  p{
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: 500;
                      color: #3C3C3B;
                      @media(max-width: 1367px) and (min-width: 1025px){
                          font-size: 15px;
                      }
                  }
                  &:before{
                      content: '';
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      width: 0;
                      height: 3px;
                      background-color: #638297;
                      transition: 0.4s ease;
                  }
                    p{
                        transition: 0.4s ease;
                    }
                  &.active {
                      position: relative;
                      p{
                          color: ${hover};
                      }
                      &:before{
                          content: '';
                          position: absolute;
                          left: 0;
                          bottom: 0;
                          width: 100%;
                          height: 3px;
                          background-color: #638297;
                      }
                  }
                  &:hover{
                      &:before{
                          width: 100%;
                      }
                      p{
                          color: ${hover};
                      }
                  }
                  @media (min-width: 1920px) {
                      height: 82px;
                      p{
                          font-size: 16px;
                      }
                  }
              }

              

              &:not(:nth-last-child(1)) a {
                  margin-right: 20px;
                  @media(min-width: 1920px){
                      margin-right: 30px;
                  }
              }


              ul {
                  position: absolute;
                  background-color: #F2F2F2;
                  padding: 30px 20px;
                  min-width: 250px;
                  visibility: hidden;
                  opacity: 0;
                  transition: .4s ease;
                  box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
                    border-radius: 5px;
                  li {
                      a {
                          font-weight: 500;
                          color: ${text};
                          height: fit-content;
                          margin-right: 0 !important;
                      }

                      &:not(:nth-last-child(1)) a {
                          border-bottom: 1px solid rgba(60, 60, 59, 0.30);
                          padding-bottom: 12px;
                          margin-bottom: 17px;
                      }
                  }
              }

              &:hover {
                  ul {
                      visibility: visible;
                      opacity: 1;
                  }
              }
          }
      }
      @media(min-width: 1920px){
          
      }
  }

  @media (max-width: 1150px) {
    .main-menu__left__logo {
      margin-right: 30px;

      img {
        height: 30px;
      }
    }

    .main-menu__left ul li {
      a {
        font-size: 15px;
      }

      &:not(:nth-last-child(1)) a {
        margin-right: 25px;
      }
    }

    .dc-btn {


      a {
        padding-right: 15px;
        padding-left: 15px;
        font-size: 14px;
      }
    }

  }

  @media (max-width: 1200px) {
    display: none;
  }

`;

// mobile menu

const StyledMobileMenu = styled.section`
    position: fixed;
    padding-top: 30px;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 99999999;
    
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible !important;
    //box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    //display: none;
    transition: all 0.5s ease-in-out;

    .dc-btn {
        width: 100%;
    }

    //&:after {
    //    content: '';
    //    position: absolute;
    //    height: 1px;
    //    background-color: rgba(255, 255, 255, 0.5);
    //    left: 0px;
    //    right: 0px;
    //    bottom: 0;
    //}
    
    .container{
        background-color: white;
        margin-right: 15px;
        margin-left: 15px;
        border-radius: 5px;
    }
    
    .active{
        a{
            color: ${hover} !important;
        }
    }


    .mobile-menu {
        .logo {
            display: flex;
            align-items: center;

            img {
                height: 35px !important;
            }
        }

        .hamburger {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding-right: 0;
            span {
                //position: absolute !important;
                //right: 15px !important;
                //height: 25px !important;
                border-radius: 0 5px 5px 0;
                img{
                    border-radius: 0 5px 5px 0;
                }

                &:nth-of-type(2) {
                    display: none !important;
                }
            }

            &.menu-open span {
                &:nth-of-type(1) {
                    display: none !important;
                }

                &:nth-of-type(2) {
                    display: block !important;
                    //right: 15px !important;
                }
            }
        }

        &__items {
            display: none;
            position: absolute;
            opacity: 0;
            background-color: #3C3C3B;
            //height: 100svh;
            top: 100px;
            left: 0;
            width: 100%;
            z-index: 99;
            padding: 55px 0 60px 0;
            //overflow: auto;
            //transition: all 0.5s ease-in-out;
            overflow: auto;

            .mobile-menu__items__contact {
                height: 50px;
                width: 100%;
                border-radius: 24px;
                text-align: center;
                background-color: ${hover};
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 70px;
                margin-top: 10px;

                p {
                    a {
                        font-size: 16px;
                        font-weight: 500;
                        color: #ffffff;
                        line-height: 24px;
                    }
                }


            }

            &__btn{
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 30px;
                border-bottom: 1px solid rgba(255,255,255,0.5);
                .dc-btn{
                    a{
                        width: 100%;
                    }
                }
            }

            &__top {
                ul {
                    li {
                        a {
                            height: 35px;
                            border-radius: 18px;
                            background-color: rgba(136, 136, 136, 0.5);
                            color: #ffffff;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 30px;

                            img {
                                margin-right: 10px !important;
                            }
                        }

                        &:nth-last-child(1) a {
                            margin-bottom: 60px;
                            background-color: ${hover};

                            &:hover {
                                color: #ffffff !important;
                            }
                        }
                    }
                }
            }

            &__ul {
                margin-top: 40px;
                margin-right: 15px;
                margin-left: 15px;
                margin-bottom: 60px;
                li {
                    padding-bottom: 20px;
                    //border-bottom: 1px solid rgba(43, 57, 68, 0.2);
                    margin-bottom: 20px;
                    //padding-right: 15px;
                    //padding-left: 15px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    &:nth-last-child(1) {
                        border-bottom: 0;
                    }

                    a {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px; /* 133.333% */
                        letter-spacing: -0.36px;
                    }

                    &.active {
                        border-color: rgba(255,255,255,0.5);

                        a {
                            //color: #CA0034;
                        }
                    }
                }

                .mobile-menu__items__bottom{
                    margin-top: 40px;
                    ul{
                        display: flex;
                        justify-content: flex-end;
						gap:40px;
                        li{
                            border: none;
                            a{
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: 500;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
            
        }

        &.menu-open {
            .mobile-menu__items {
                display: block;
            }
        }

        //accordion
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    background-color: transparent;
                    text-transform: capitalize;
                    width: 100%;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0;
                    border: none;
                    box-shadow: none;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                        // border-bottom: 1px solid ${hover};
                    color: #FFF;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    //padding-left: 15px;
                    //padding-right: 15px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    &.collapsed {
                        color: #FFF;

                    }


                    img {
                        //height: 10px;
                        //margin-top: 6px;

                        &:nth-last-child(2) {
                            display: none !important;
                        }

                        &:nth-last-child(1) {
                            display: block !important;
                        }
                    }

                    &.collapsed {
                        img {
                            &:nth-last-child(2) {
                                display: block !important;
                            }

                            &:nth-last-child(1) {
                                display: none !important;
                            }
                        }
                    }

                    &.collapsed {
                        //border-bottom: 1px solid #e1e4e6;
                    }
                }

                &.active {
                    .accordion-button {
                        //border-bottom: 1px solid rgb(25, 97, 172);
                        color: #638297;
                    }
                }
            }

            .accordion-body {
                //padding-bottom: 40px;
                //padding-top: 40px;
                background-color: #3C3C3B;
                margin-bottom: 30px;
                //padding-top: 20px;

                ul {
                    //margin-left: 15px;
                    //margin-right: 15px;
                    background-color: #7497AC;
                    border-radius: 10px;
                    li {
                        border: none;
                        margin: 0;
                        font-size: 12px !important;
                        line-height: 18px !important;
                        padding: 0;
                        a {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #FFF;
                            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                            display: block;
                            padding: 20px 15px 15px 15px;
                            
                            svg {
                                display: none;
                            }

                        }

                        //&:nth-of-type(1) a {
                        //    padding-top: 0;
                        //}

                        &:nth-last-of-type(1) a {
                            border: none;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &:nth-last-child(1) {
                .accordion-button {
                    &.collapsed {
                        border: none;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    }

                }
            }
        }
    }

    @media (min-width:1201px) {
        display: none;
    }
    
    .mobile-search{
        margin-left: 15px;
        margin-right: 15px;
        a{
            width: 100%;
        }
        //form {
        //    //height: 100%;
        //    width: 100%;
        //    border: 1px solid #FFF;
        //    border-radius: 45px;
        //    padding: 0px 15px;
        //    z-index: 1;
        //    background-color: transparent;
        //    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        //    cursor: pointer;
        //
        //    .search-input {
        //        display: flex;
        //        align-items: center;
        //        justify-content: space-between;
        //        //height: 100%;
        //        img{
        //            margin-right: 10px;
        //        }
        //        .form-control {
        //            width: 100%;
        //            height: 100%;
        //            padding: 12px 0 9px 0px;
        //            font-size: 16px;
        //            line-height: 24px;
        //            font-weight: 500;
        //            color: #ffffff;
        //            background-color: transparent;
        //            border: none;
        //            border-radius: 0;
        //            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        //            //margin: 0 0 0 30px;
        //            margin-bottom: 0 !important;
        //
        //            &::placeholder {
        //                color: #FFFFFF !important;
        //                font-size: 14px;
        //            }
        //        }
        //
        //        input {
        //
        //            color: #FFFFFF !important;
        //            &::placeholder {
        //                color: #FFFFFF !important;
        //            }
        //        }
        //    }
        //    
        //}
    }
    
    &.menu-visible{
        background-color: #3C3C3B;
        transition: all 0.5s ease-in-out;
    }
    



`;

export default MyComponent;
