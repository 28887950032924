'use client'
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {DrawSVGPlugin} from "gsap/dist/DrawSVGPlugin";
import {usePathname} from 'next/navigation'
import {hover} from "@/styles/globalStyleVars";

const MyComponent = () => {
    let tl = new TimelineLite();
    let t2 = new TimelineLite();
    const pathName = usePathname();
    gsap.registerPlugin(DrawSVGPlugin);
    useEffect(() => {
        const updateHeight = () => {
            const pageChangeElement = document.querySelector('.page-change');
            if (pageChangeElement) {
                pageChangeElement.style.height = `${window.innerHeight}px`;
            }
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        // document.body.classList.add('no-scroll');

        tl.fromTo('.page-change', 0.4, {
            display: 'flex',
            opacity: 1,
        }, {
            duration: 2,
            delay:1.5,
            opacity: 0,
            display: 'none',
        });

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [pathName]);


    return (
        <StyledComponent className={'page-change'}>
            <img src="/images/static/footer-logo.svg" alt=""/>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 100vh;
    position: fixed;
    background-color:#3C3C3B;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
        width: 180px;
    }

    @media (max-width: 600px) {
        svg {
            width: 200px;
        }
    }
`;

export default MyComponent;
