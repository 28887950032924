import React, { useEffect, useState } from 'react';
import styled, { keyframes } from "styled-components";
import { CSSPlugin, gsap } from "gsap";
import { hover, primary } from "@/styles/globalStyleVars";

gsap.registerPlugin(CSSPlugin);

const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 0.7;
    }
`;

const MyComponent = () => {
    const [offset, setOffset] = useState(90);

    useEffect(() => {
        const updateOffset = () => {
            setOffset(document.querySelector('.container')?.offsetLeft || 90);
        };
        updateOffset();
        window.addEventListener('resize', updateOffset);
        return () => window.removeEventListener('resize', updateOffset);
    }, []);

    return (
        <StyledComponent className="floating-icon" offset={offset}>
            <ul>
                <li>
                    <a target="_blank" href="tel:+8801313020291">
                        <img src="/images/static/call.svg" alt="Call Icon" />
                    </a>
                </li>
            </ul>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: fixed;
    // right: ${p => p.offset}px;
    right: 40px;
    bottom: 60px;
    z-index: 105;

    @media (max-width: 991px) {
        right: 20px;
        bottom: 30px;
    }

    ul {
        li {
            a {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.15);
                transition: 0.5s ease;
                z-index: 2; /* Ensure the icon is above the pulse effect */

                &:before {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                    background-color: ${primary};
                    animation: ${pulseAnimation} 2s infinite;
                    z-index: -1;
                    mix-blend-mode: hard-light;
                }

                &:hover::before {
                    background-color: ${hover};
                }

                img {
                    height: 20px;
                    width: 20px;
                }
            }

            &:not(:nth-last-child(1)) {
                margin-bottom: 20px;
            }

            &.mobile-click {
                display: none;
            }
        }
    }

    @media (max-width: 768px) {
        right: 15px;

        ul li {
            &:not(:nth-last-child(1)) {
                transform: translateY(100%);
                display: none;
                opacity: 0;
            }

            &.mobile-click {
                display: block;
            }
        }
    }
`;

export default MyComponent;
