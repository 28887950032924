'use client'
import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {usePathname} from "next/navigation";

gsap.registerPlugin(ScrollTrigger);

const useSectionInView = (className, bodyClassName) => {
    const pathname = usePathname()
    useEffect(() => {
        // Select all sections with the given class name
        const sections = document.querySelectorAll(`.${className}`);

        // Create ScrollTriggers for each section
        const triggers = Array.from(sections).map(section =>
            ScrollTrigger.create({
                trigger: section,
                start: 'top top',
                end: 'bottom top',
                onEnter: () => document.body.classList.add(bodyClassName),
                onLeave: () => document.body.classList.remove(bodyClassName),
                onEnterBack: () => document.body.classList.add(bodyClassName),
                onLeaveBack: () => document.body.classList.remove(bodyClassName),
            })
        );

        // Cleanup function to remove triggers
        return () => {
            triggers.forEach(trigger => trigger.kill());
            document.body.classList.remove(bodyClassName);
        };
    }, [className, bodyClassName,pathname]);
};

export default useSectionInView;
