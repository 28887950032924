'use client';

import StyledComponentsRegistry from "@/lib/registry";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import './globals.css';
import './global.css';
import Menu from "@/components/Menu";
import { gsap } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {Suspense, useEffect, useRef, useState} from "react";
import { usePathname } from "next/navigation";
import { useGSAP } from "@gsap/react";
import PageTransition from "@/components/PageTransition";
import Messenger from "@/components/Messenger";
import useSectionInView from "@/components/PageSections";
import Footer from "@/components/Footer";
import {getFooterData} from "@/api";
import Preloader from "@/components/Preloader";
import ErrorBoundary from "@/components/ErrorBoundary";



export default function RootLayout({ children }) {
    const pathName = usePathname();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
    const [isLoading, setIsLoading] = useState(true); // Add a state for loading

    const el = useRef();
    let smoother;

    useGSAP(() => {
        if (typeof window !== 'undefined' && window.innerWidth > 1200 && !pathName.startsWith('/about-us')) {
            smoother = ScrollSmoother.create({
                smooth: 2,
                effects: true,
            });
        }
        ScrollTrigger.refresh();

        return () => {
            smoother?.kill();
        };
    }, [pathName]);

    // window.scroll(0, 10);


    useEffect(() => {
        window.scroll(0, 10);
        if (pathName.startsWith('/newsroom/')) {
            document.body.classList.add('in-news-page');
        } else {
            document.body.classList.remove('in-news-page');
        }
    }, [pathName,pathName.startsWith('/newsroom/')]);

    useSectionInView('watch-section', 'in-news-page');



    return (
        <html lang="en">
        <head>
            <link rel="icon" type="image/png" href="/images/static/fav.png"/>
            <meta content="#000000" name="theme-color"/>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-EPE1XLE0CN"></script>
            <script dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-EPE1XLE0CN');
                `
            }}>
            </script>
        </head>
        <body>
        <StyledComponentsRegistry>
            {isLoading && <Preloader/>}
                {/*<PageTransition/>*/}
            <ErrorBoundary>
                <ToastContainer/>
                <Messenger/>
                <Menu/>
                {pathName.startsWith('/about-us') ? (
                    <>
                    {children}
                        <Footer />
                    </>
                ) : (
                    <div ref={el} id="smooth-wrapper">
                        <div id="smooth-content">
                            <div key={pathName}>
                                {children}
                                <Footer/>
                            </div>
                        </div>
                    </div>
                )}
            </ErrorBoundary>

        </StyledComponentsRegistry>
        </body>
        </html>
    );
}
